import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Registration from "../components/registration/registration"

const RegistrationPage = () => {
  return (
    <Layout>
      <Header />
      <Registration />
    </Layout>
  )
}

export default RegistrationPage
